// CSS IMPORTS 
import { Link } from 'react-router-dom';
import '../css/inventory.css';

const Inventory = () => {
    return ( 
        <div className="inventory">
            <h2>Inventory</h2>
            <br />
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="content-holder">
                        
                        <div className="row">
                            <div className="col-lg-12 col-12">
                                    
                                <div className="MyTable">
                                    <table className="table table-striped">
                                        <thead className="">
                                            <tr>
                                                <td>Item Name</td>
                                                <td>Item Code</td>
                                                <td>Quantity</td>
                                                <td>Unit of Measure</td>
                                                <td>Price</td>
                                                <td>Category</td>
                                                <td>Action</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Item1</td>
                                                <td>SKU111</td>
                                                <td>2</td>
                                                <td>Kilo</td>
                                                <td>1000</td>
                                                <td>Medical</td>
                                                <td>
                                                    <span style={{color: "orange"}}><i className="bi bi-pencil"></i></span>
                                                    <span style={{color: "red"}}><i className="bi bi-x"></i></span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Item 2</td>
                                                <td>SKU X</td>
                                                <td>3</td>
                                                <td>Liters</td>
                                                <td>300</td>
                                                <td>Necessity</td>
                                                <td>
                                                    <span style={{color: "orange"}}><i className="bi bi-pencil"></i></span>
                                                    <span style={{color: "red"}}><i className="bi bi-x"></i></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            
                            </div>
                        </div>

                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                <li className="page-item"><a className="page-link" href="#p">Previous</a></li>
                                <li className="page-item"><a className="page-link" href="#1">1</a></li>
                                <li className="page-item"><a className="page-link" href="#2">2</a></li>
                                <li className="page-item"><a className="page-link" href="#3">3</a></li>
                                <li className="page-item"><a className="page-link" href="#n">Next</a></li>
                            </ul>
                        </nav>

                        <Link to="/inventory/create">
                            <button type="button" className="btn btn-success">Add Product</button>
                        </Link>


                    </div>
                </div>
            </div>
                
            
            
        </div>
     );
}
 
export default Inventory;