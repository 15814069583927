import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useStateValue } from "./contextProvider";
import './sidebar.css';

const Sidebar = () => {
    const [{ isCollapsed }, dispatch] = useStateValue();
    
    // State to track open/closed status of each submenu
    const [openSubmenus, setOpenSubmenus] = useState({
        procurement: false,
        inventory: false
    });

    const [isMobile, setIsMobile] = useState(window.innerWidth < 650);

    const sidebarControl = () => {
        dispatch({
            type: "SET_MENUCOLLAPSE",
            isCollapsed: !isCollapsed,
        });

        if (isCollapsed) {
            // Sidebar is collapsing; Close all submenus
            setOpenSubmenus({
                procurement: false,
                inventory: false
            });
        }
    }

    const handleLinkClick = () => {
        if (isMobile) {
            sidebarControl(); // Collapse the sidebar on link click if in mobile view
        }
    };

    const handleSubTree = (submenu) => {
        setOpenSubmenus(prevState => ({
            ...prevState,
            [submenu]: !prevState[submenu]
        }));
    }

    const handleMouseOver = () => {
        console.log("hover");
    }

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 650);
        };

        window.addEventListener('resize', handleResize);

        // Clean up listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // When sidebar is collapsed and screen size changes, close submenus
        if (isCollapsed) {
            setOpenSubmenus({
                procurement: false,
                inventory: false
            });
        }
    }, [isCollapsed]);

    return ( 
        <div>
            <div className={`sidebar ${isCollapsed ? 'isCollapsed' : ''}`}>
                <div className="sidebar-title">
                    <div className={`sidebar-titleText ${isCollapsed ? 'd-none' : ''}`}>
                        <Link to="/">TUPS INVENTORY</Link>
                    </div>
                    <div className="sidebar-btn">
                        <button type="button" onClick={sidebarControl}>
                            <i className="bi bi-list"></i>
                        </button>
                    </div>
                </div>
                <br />
                <br />
                <div className="sidebar-profile">
                    <div className="sidebar-picture">
                        <img src="/assets/profile/LOGO.png" alt="" />
                    </div>
                </div>
                <br />
                <ul>
                    <li>
                        <NavLink onClick={handleLinkClick} to="/">
                            <span className="sidebar-icon">
                                <i className={`bi bi-house`}></i>
                            </span>
                            <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Dashboard</span>
                        </NavLink>
                    </li>
                    <li>
                        <span className="withSub">
                            <span className="sidebar-icon" onMouseOver={handleMouseOver}>
                                <i className={`bi bi-shop-window`}></i>
                            </span>
                            <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Procurement</span>
                            <span className={`chevron ${isCollapsed ? 'd-none' : ''}`} onClick={() => handleSubTree('procurement')}>
                                <i className={`bi bi-chevron-${openSubmenus.procurement ? 'up' : 'down'}`}></i>
                            </span>

                            <div className="tooltip-menu">
                                <NavLink onClick={handleLinkClick} to="/procurement/delivery">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link`}>Delivery 1</span>
                                </NavLink>
                                <NavLink onClick={handleLinkClick} to="/procurement/inventory">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link`}>Inventory 1</span>
                                </NavLink>
                                <NavLink onClick={handleLinkClick} to="/procurement/purchases">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link`}>Purchases 1</span>
                                </NavLink>
                            </div>

                            <span className={`submenu ${openSubmenus.procurement ? 'open' : ''}`}>
                                <NavLink onClick={handleLinkClick} to="/procurement/delivery">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Delivery</span>
                                </NavLink>
                                
                                <NavLink onClick={handleLinkClick} to="/procurement/inventory">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Inventory</span>
                                </NavLink>
                                
                                <NavLink onClick={handleLinkClick} to="/procurement/purchases">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Purchases</span>
                                </NavLink>
                            </span>
                        </span>
                    </li>
                    <li>
                        <span className="withSub">
                            <span className="sidebar-icon">
                                <i className={`bi bi-shop-window`}></i>
                            </span>
                            <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Inventory</span>
                            <span className={`chevron ${isCollapsed ? 'd-none' : ''}`} onClick={() => handleSubTree('inventory')}>
                                <i className={`bi bi-chevron-${openSubmenus.inventory ? 'up' : 'down'}`}></i>
                            </span>

                            <div className="tooltip-menu">
                                <NavLink onClick={handleLinkClick} to="/procurement/delivery">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link`}>Delivery 2</span>
                                </NavLink>
                                <NavLink onClick={handleLinkClick} to="/procurement/inventory">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link`}>Inventory 2</span>
                                </NavLink>
                                <NavLink onClick={handleLinkClick} to="/procurement/purchases">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link`}>Purchases 2</span>
                                </NavLink>
                            </div>

                            <span className={`submenu ${openSubmenus.inventory ? 'open' : ''}`}>
                                <NavLink onClick={handleLinkClick} to="/inventory/delivery">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Delivery</span>
                                </NavLink>
                                
                                <NavLink onClick={handleLinkClick} to="/inventory/inventory">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Inventory</span>
                                </NavLink>
                                
                                <NavLink onClick={handleLinkClick} to="/inventory/purchases">
                                    <span className="sidebar-icon">
                                        <i className={`bi bi-shop-window`}></i>
                                    </span>
                                    <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Purchases</span>
                                </NavLink>
                            </span>
                        </span>
                    </li>
                    <li>
                        <NavLink onClick={handleLinkClick} to="/sales">
                            <span className="sidebar-icon">
                                <i className={`bi bi-file-earmark-check`}></i>
                            </span>
                            <span className={`sidebar-link ${isCollapsed ? 'd-none' : ''}`}>Sales</span>
                        </NavLink>
                    </li>
                </ul>

                <div className="sidebar-footer">
                    © 2024 JC Santos. All Rights Reserved.
                </div>
            </div>
        </div>
    );
}

export default Sidebar;
