const Create = () => {
    return ( 
        <div className="inventory_create">
            <h2>Create New Product</h2>
            <br />
            
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="card" >
                        <div className="card-body">
                            
                            <div className="row">
                                <div className="col-lg-1 col-4">
                                    <p>Item Name</p>
                                </div>
                                <div className="col-lg-4 col-8">
                                    <input type="text" className="form-control" name="itemname" />
                                </div>
                                <div className="col-lg-2 col-4">
                                    <p>Item Code</p>
                                </div>
                                <div className="col-lg-5 col-8">
                                    <input type="text" className="form-control" name="itemcode" />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-1 col-4">
                                    <p>Category</p>
                                </div>
                                <div className="col-lg-4 col-8">
                                    <input type="text" className="form-control" name="category" />
                                </div>
                                <div className="col-lg-2 col-4">
                                    <p>Unit of Measure</p>
                                </div>
                                <div className="col-lg-5 col-8">
                                    <input type="text" className="form-control" name="unit" />
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-lg-1 col-4">
                                    <p>Quantity</p>
                                </div>
                                <div className="col-lg-4 col-8">
                                    <input type="text" className="form-control" name="quantity" />
                                </div>
                                <div className="col-lg-2 col-4">
                                    <p>Price(PHP)</p>
                                </div>
                                <div className="col-lg-5 col-8">
                                    <input type="text" className="form-control" name="price" />
                                </div>
                            </div>

                            <br />
                            
                            <div className="row">
                                <div className="col-lg-2 col-4">
                                    <button type="button" className="btn btn-secondary w-100">Save</button>
                                </div>
                                <div className="col-lg-2 col-4">
                                    <button type="button" className="btn btn-danger w-100">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
     );
}
 
export default Create;