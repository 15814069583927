// REACT HOOK IMPORTS
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useStateValue } from "./contextProvider";

// COMPONENT IMPORTS
import Sidebar from "./Sidebar";
import Dashboard from "./components/Dashboard";
import Inventory from "./components/Inventory";
import Create from "./components/inventory/Create";
import Sales from "./components/Sales";
import Reports from "./components/Reports";
import Topbar from "./Topbar";

// CSS IMPORTS
import './App.css';

function App() {
  const [{ isCollapsed }] = useStateValue();

  
  return (
    <div className="App">
      
      <BrowserRouter>
        {/* TOPBAR HERE */}
        <Topbar />

        <div className="layout">
          {/* SIDEBAR HERE */}
          <Sidebar />

          {/* MAIN CONTENT HERE */}
          <div className={`main-content ${isCollapsed ? 'isCollapsed' : ''}`}>
            <Routes>
              <Route path="/procurement" element={<Reports />} />
              <Route path="/inventory/create" element={<Create />} />
              <Route path="/procurement/inventory" element={<Inventory />} />
              <Route path="/sales" element={<Sales />} />
              <Route path="/" element={<Dashboard />} />
              
            </Routes>
          </div>
        </div>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
