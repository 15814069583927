export const initialState = {
    isCollapsed: false
  };

  const reducer = (state, action) => {
    // console.log(action);

    switch(action.type){
        case 'SET_MENUCOLLAPSE':
            return {
                ...state,
                isCollapsed: action.isCollapsed
            } 
        default:
            return state;
    }
  }
  export default reducer;