const Topbar = () => {
    return ( 
        <div className="topbar">
          {/* ITEM AT MIDDLE */}

          {/* USER */}
          <span>
            <h3>
              <i className="bi bi-person-circle"></i>
            </h3>
          </span>
        </div>
     );
}
 
export default Topbar;