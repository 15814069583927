// CSS IMPORTS 
import '../css/dashboard.css';

const Dashboard = () => {

    return ( 
        <div>
            <div className={`dashboard`}>
                <div className="title">    
                    <span className="title-logo">
                        <i className="bi bi-graph-up"></i>
                    </span>
                    <div className="title-text">Dashboard</div>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sed volutpat mauris. Suspendisse luctus nibh vitae lacus fringilla, eget vestibulum ex tincidunt.</p>
                
                <div className="row">
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-details">
                                    <div>
                                        <h4>Today's Sales</h4>
                                    </div>
                                    <div>10,000</div>
                                </div>
                                <div className="card-icon">
                                    <span>
                                        <i className="bi bi-currency-dollar"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-details">
                                    <div>
                                        <h4>Today's Purchase</h4>
                                    </div>
                                    <div>6,255</div>
                                </div>
                                <div className="card-icon">
                                    <span>
                                        <i className="bi bi-cart"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-details">
                                    <div>
                                        <h4>Today's Return</h4>
                                    </div>
                                    <div>156</div>
                                </div>
                                <div className="card-icon">
                                    <span>
                                        <i className="bi bi-arrow-return-left"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-details">
                                    <div>
                                        <h4>Today's Deliveries</h4>
                                    </div>
                                    <div>2000</div>
                                </div>
                                <div className="card-icon">
                                    <span>
                                        <i className="bi bi-truck"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <br />
                
                <div className="row">
                    <div className="col-lg-6">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vulputate odio ipsum, in pellentesque odio ornare non. Suspendisse malesuada sapien sed urna venenatis, id lacinia nisi ornare. Vivamus vulputate libero eget enim euismod, ut mollis magna tincidunt. Praesent varius blandit ex sit amet finibus. Ut faucibus tempus mattis. Vestibulum et sem dolor. Curabitur dapibus lectus sit amet tortor vulputate consectetur. Praesent ultrices velit id metus ornare rutrum.

                            Sed venenatis eros nec tortor ultrices, vitae aliquam lorem placerat. Pellentesque malesuada eros vel porttitor congue. Praesent vitae velit id turpis sagittis elementum. Pellentesque ut maximus metus. Curabitur eros libero, mattis et lorem ultricies, interdum ultricies urna. Vestibulum eleifend, tellus malesuada mollis suscipit, magna risus lobortis diam, eu tempus quam nibh ac sem. Maecenas tincidunt, est nec venenatis tincidunt, arcu augue consequat dui, et lacinia mauris risus sed tortor. Integer molestie lobortis feugiat. Nulla facilisi. Cras ligula lorem, varius sollicitudin enim id, dictum venenatis ante. Aliquam at lacus a nunc pulvinar sollicitudin.

                            Donec sollicitudin efficitur sapien vitae fringilla. Curabitur molestie scelerisque massa, vel consequat turpis molestie mollis. Aenean at nisi ullamcorper, iaculis arcu nec, tempus libero. Morbi sagittis lobortis nisl molestie euismod. Pellentesque quis lectus pulvinar elit auctor ultricies. Donec arcu nisl, molestie sagittis tempus vel, fringilla at nisi. Nunc feugiat blandit diam nec facilisis. Cras sed semper diam. Nunc arcu sapien, imperdiet nec bibendum a, malesuada non augue. Donec quis arcu eu erat efficitur tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam gravida nisl in ex pretium fringilla. Suspendisse potenti.</p>
                    </div>
                    <div className="col-lg-6">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vulputate odio ipsum, in pellentesque odio ornare non. Suspendisse malesuada sapien sed urna venenatis, id lacinia nisi ornare. Vivamus vulputate libero eget enim euismod, ut mollis magna tincidunt. Praesent varius blandit ex sit amet finibus. Ut faucibus tempus mattis. Vestibulum et sem dolor. Curabitur dapibus lectus sit amet tortor vulputate consectetur. Praesent ultrices velit id metus ornare rutrum.

                            Sed venenatis eros nec tortor ultrices, vitae aliquam lorem placerat. Pellentesque malesuada eros vel porttitor congue. Praesent vitae velit id turpis sagittis elementum. Pellentesque ut maximus metus. Curabitur eros libero, mattis et lorem ultricies, interdum ultricies urna. Vestibulum eleifend, tellus malesuada mollis suscipit, magna risus lobortis diam, eu tempus quam nibh ac sem. Maecenas tincidunt, est nec venenatis tincidunt, arcu augue consequat dui, et lacinia mauris risus sed tortor. Integer molestie lobortis feugiat. Nulla facilisi. Cras ligula lorem, varius sollicitudin enim id, dictum venenatis ante. Aliquam at lacus a nunc pulvinar sollicitudin.

                            Donec sollicitudin efficitur sapien vitae fringilla. Curabitur molestie scelerisque massa, vel consequat turpis molestie mollis. Aenean at nisi ullamcorper, iaculis arcu nec, tempus libero. Morbi sagittis lobortis nisl molestie euismod. Pellentesque quis lectus pulvinar elit auctor ultricies. Donec arcu nisl, molestie sagittis tempus vel, fringilla at nisi. Nunc feugiat blandit diam nec facilisis. Cras sed semper diam. Nunc arcu sapien, imperdiet nec bibendum a, malesuada non augue. Donec quis arcu eu erat efficitur tincidunt. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nullam gravida nisl in ex pretium fringilla. Suspendisse potenti.

                            Cras vel ante nibh. Duis non nibh in erat fermentum mollis. Nullam turpis massa, vestibulum sit amet consectetur et, lobortis et turpis. Proin ullamcorper semper gravida. Integer eget dolor enim. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Quisque ut suscipit eros, sit amet malesuada urna. In pharetra ultricies nulla eu luctus.</p>
                    </div>
                </div>
           
            </div>
            
        </div>
     );
}
 
export default Dashboard;